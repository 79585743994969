"use client"; // 标记为客户端组件

import { useState, useEffect } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Frame } from '@/components/frame';

import aiBGImage from '../../components/images/ai_staff_bg.png';
import kefuImage from '../../components/images/kefu.png';
// 添加美女客服的图片
import customerServiceImage from '../../components/images/customer_service.png'; // 替换为实际的客服图片路径

const Line: React.FC<{ className?: string }> = ({ className }) => {
  return <div className="bg-clip-border overflow-clip border-b border-[#FFFFFF] md:border-[#FFFFFF]/15 text-[#FFD55D] rounded-lg"></div>;
};

export default function StaffIndexPage() {
  const [isIframeOpen, setIsIframeOpen] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 }); // 按钮和 iframe 初始位置
  const [iframePosition, setIframePosition] = useState({ x: 0, y: 0 }); // iframe 的初始位置
  const [isDragging, setIsDragging] = useState(false);
  const [isIframeDragging, setIsIframeDragging] = useState(false); // 用于拖动 iframe
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [iframeSize, setIframeSize] = useState({ width: 500, height: 700 }); // iframe 默认大小

  useEffect(() => {
    // 设置按钮默认位置为右下角
    const defaultX = window.innerWidth - 100; // 100 是按钮宽度
    const defaultY = window.innerHeight - 120; // 120 是按钮高度和底部间距
    setPosition({ x: defaultX, y: defaultY });

    // 动态调整 iframe 大小
    const adjustIframeSize = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      // 在手机屏幕时调整 iframe 尺寸
      if (screenWidth < 600) {
        setIframeSize({
          width: screenWidth * 0.9,  // 90% 的屏幕宽度
          height: screenHeight * 0.7, // 70% 的屏幕高度
        });
      } else {
        // 非手机屏幕，使用固定尺寸
        setIframeSize({ width: 500, height: 700 });
      }
    };

    // 调整大小时，重新计算 iframe 尺寸
    window.addEventListener('resize', adjustIframeSize);
    adjustIframeSize(); // 初次加载时计算大小

    return () => window.removeEventListener('resize', adjustIframeSize);
  }, []);

  const toggleIframe = () => {
    if (!isDragging && !isIframeDragging) {
      if (!isIframeOpen) {
        // 动态计算 iframe 的位置，确保它不会超出可视范围
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        const iframeWidth = iframeSize.width;
        const iframeHeight = iframeSize.height;

        let newX = position.x;
        let newY = position.y;

        // 调整位置确保 iframe 不超出可视范围
        newX = Math.min(newX, windowWidth - iframeWidth); // 确保右侧不超出
        newY = Math.min(newY, windowHeight - iframeHeight); // 确保底部不超出

        setIframePosition({ x: newX, y: newY });
      }

      setIsIframeOpen(!isIframeOpen);
    }
  };

  // 开始拖动按钮（鼠标事件）
  const handleMouseDownButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    setIsDragging(true);
    setOffset({
      x: e.clientX - position.x, // 记录鼠标点击时的偏移
      y: e.clientY - position.y,
    });
  };

  // 开始拖动按钮（触摸事件）
  const handleTouchStartButton = (e: React.TouchEvent<HTMLButtonElement>) => {
    setIsDragging(true);
    setOffset({
      x: e.touches[0].clientX - position.x, // 记录手指触摸时的偏移
      y: e.touches[0].clientY - position.y,
    });
  };

  // 开始拖动 iframe 标题栏（鼠标事件）
  const handleMouseDownIframe = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsIframeDragging(true);
    setOffset({
      x: e.clientX - iframePosition.x, // 记录鼠标点击时的偏移
      y: e.clientY - iframePosition.y,
    });
  };

  // 开始拖动 iframe 标题栏（触摸事件）
  const handleTouchStartIframe = (e: React.TouchEvent<HTMLDivElement>) => {
    setIsIframeDragging(true);
    setOffset({
      x: e.touches[0].clientX - iframePosition.x, // 记录手指触摸时的偏移
      y: e.touches[0].clientY - iframePosition.y,
    });
  };

  // 拖动时更新按钮或 iframe 的位置，并防止 iframe 超出可视范围（鼠标和触摸事件通用）
  const handleMove = (x: number, y: number, isDraggingIframe: boolean) => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const iframeWidth = iframeSize.width; // 动态调整 iframe 宽度
    const iframeHeight = iframeSize.height; // 动态调整 iframe 高度

    if (isDragging) {
      setPosition({
        x: x - offset.x,
        y: y - offset.y,
      });
    }
    if (isDraggingIframe) {
      // 限制 iframe 拖动范围，防止其超出可视区域
      const newX = Math.min(Math.max(x - offset.x, 0), windowWidth - iframeWidth); // x 轴边界限制
      const newY = Math.min(Math.max(y - offset.y, 0), windowHeight - iframeHeight); // y 轴边界限制

      setIframePosition({
        x: newX,
        y: newY,
      });
    }
  };

  // 鼠标移动处理
  const handleMouseMove = (e: MouseEvent) => {
    handleMove(e.clientX, e.clientY, isIframeDragging);
  };

  // 触摸移动处理
  const handleTouchMove = (e: TouchEvent) => {
    handleMove(e.touches[0].clientX, e.touches[0].clientY, isIframeDragging);
  };

  // 停止拖动（鼠标事件）
  const handleMouseUp = () => {
    setIsDragging(false);
    setIsIframeDragging(false);
  };

  // 停止拖动（触摸事件）
  const handleTouchEnd = () => {
    setIsDragging(false);
    setIsIframeDragging(false);
  };

  // 添加和移除全局事件监听（鼠标和触摸事件）
  useEffect(() => {
    if (isDragging || isIframeDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("touchmove", handleTouchMove); // 添加触摸移动事件
      window.addEventListener("mouseup", handleMouseUp);
      window.addEventListener("touchend", handleTouchEnd); // 添加触摸结束事件
    } else {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("touchmove", handleTouchMove); // 移除触摸移动事件
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("touchend", handleTouchEnd); // 移除触摸结束事件
    }
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("touchend", handleTouchEnd);
    };
  }, [isDragging, isIframeDragging]);

  return (
    <div className="lg:px-0" id="index-target">
      <div className={cn("flex flex-col items-start relative z-1 justify-center min-h-72 mx-auto text-center overflow", "bg-[#8fbdb9]/5")}>
        <figure className="relative inset-x-0 z-[-1] top-[1px] pointer-events-none w-full">
          <Image src={aiBGImage} className="w-full h-full py-[1rem]" alt="Hero Image" />
        </figure>
        <div className="absolute w-full">
          <div className="gap-3 max-w-7xl m-auto text-left text-[#fff]">
            <h1 className="font-medium text-[1rem] lg:text-[4rem] text-[#fff] leading-[1.5rem] lg:leading-[5rem] text-left mb-[1rem]">
              美国移民
            </h1>
            <p className="my-[1.6rem]">民营企业家的首选美国移民路径</p>
            <Link href="tel:19924370039">
              <Button variant="ghost" className="bg-[#000] text-[#fff] gap-2 bg-transparent border border-white">
                <span className="">联系我们</span>
              </Button>
            </Link>
          </div>
        </div>
      </div>

      <Line />

      <div className="flex flex-row items-start relative justify-between bg-white max-w-7xl m-auto py-[1.5rem]">
        <h4 className="text-[#17776C]">移民咨询热线 199 2437 0039</h4>
        <Link href="tel:19924370039">
          <Button
            variant="ghost"
            className="text-[#fff] gap-2 bg-transparent bg-[#17776C] hover:bg-[#26988b] border border-[#17776C] text-[#17776C]"
          >
            <span className="text-[#fff]">立即联系</span>
          </Button>
        </Link>
      </div>

      <div className={cn("px-4 lg:px-0 text-[#08090B] flex flex-col items-center justify-center bg-[#F0F0F0]", "py-[2.5rem]", "w-full flex-1 max-w-7xl mx-auto ")}>
        <div className="flex flex-col order-1 w-full xl:order-2 md:pl-12">
          <h2 className="flex justify-start my-6 text-3xl font-medium leading-10 blog-heading-gradient sm:text-center xl:justify-start xl:text-left">
            专业客服，全程服务
          </h2>
          <p className="flex justify-start text-base font-normal leading-7 lg:pr-16 xl:justify-start">
            完善的客户体系，丰富的移民办理经验，为您和您的家人能够顺利抵达大洋彼岸保驾护航
          </p>
        </div>

        <div className="flex flex-col xl:flex-row w-full relative z-1 max-w-7xl m-auto">
          <Frame className={cn("order-2 w-full my-auto shadow-sm xl:order-2 rounded")}>
            <Image src={kefuImage} alt="kefu Image" />
          </Frame>
        </div>
      </div>

      {/* 当 iframe 未打开时，显示按钮 */}
      {!isIframeOpen && (
        <button
          onMouseDown={handleMouseDownButton} // 鼠标事件
          onTouchStart={handleTouchStartButton} // 触摸事件
          onClick={toggleIframe} // 确保按钮点击事件
          style={{
            position: "fixed",
            left: `${position.x}px`,
            top: `${position.y}px`,
            width: '80px', // 按钮大小保持为 80px
            height: '80px',
            border: '2px solid #007BFF',
            cursor: isDragging ? 'grabbing' : 'grab', // 拖动时手型变化
            zIndex: 50,
          }}
          className="rounded-full bg-transparent shadow-lg flex items-center justify-center"
        >
          {/* 客服图片 */}
          <Image src={customerServiceImage} alt="Customer Service" className="rounded-full w-full h-full object-cover" />
        </button>
      )}

      {/* 当 iframe 打开时，显示 iframe，并隐藏按钮 */}
      {isIframeOpen && (
        <div
          className="fixed bg-white shadow-lg z-50"
          style={{
            left: `${iframePosition.x}px`,  // iframe 的位置可以拖动
            top: `${iframePosition.y}px`,
            width: `${iframeSize.width}px`, // 动态调整 iframe 的宽度
            height: `${iframeSize.height}px`, // 动态调整 iframe 的高度
          }}
        >
          {/* 标题栏作为拖动区域 */}
          <div
            onMouseDown={handleMouseDownIframe} // 鼠标事件
            onTouchStart={handleTouchStartIframe} // 触摸事件
            style={{
              width: '100%',
              height: '40px',
              backgroundColor: '#007BFF',
              cursor: isIframeDragging ? 'grabbing' : 'grab',
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              padding: '0 10px',
            }}
          >
            <span>拖动窗口</span>
            <button onClick={toggleIframe} className="ml-auto bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center">
              X
            </button>
          </div>
          {/* iframe 内容 */}
          <iframe
            src="https://www.jellydropsllc.com/staffai/57?serial_no=GBgouzexlKNcsUBW_ewpI5jdr"
            width="100%" // iframe 内部宽度为 100%
            height="100%" // iframe 内部高度为 100%
            className="border-none"
          ></iframe>
        </div>
      )}
    </div>
  );
}
